// @ts-ignore
import { getMissionStatements } from "api";
import { ActionTypes } from "./types";

// @ts-ignore
import { getCookie } from "utils/StorageVariables";
import Notify from "utils/notify";

export const ListMissionStatements = () => async (dispatch: any, getState: any) => {
  const { masterPlanDetails } = getState().RetrieveMasterPlanDetailsReducer;
  const { data: areas, customData: customAreas } = getState().listAreaReducer;

  try {
    dispatch({ type: ActionTypes.LOADING });

    const user = getCookie('userData') ? JSON.parse(getCookie('userData')).id : null

    const filtersParams: { [key: string]: any } = {
      user,
      goal__parent_area__parent_masterplan: masterPlanDetails.id,
    };

    const { data } = await getMissionStatements(filtersParams);

    const parsedData = data.map((item: any) => {
      const relatedArea = masterPlanDetails.areas.find((area: any) => area.goal.id === item.goal);

      if (relatedArea) {
        const area = [...areas, ...customAreas].find((area: any) => area.name === relatedArea.name);

        if (area) {
          return {
            ...item,
            area: relatedArea.name,
            color: relatedArea.color,
            icon: area.icon
          }
        }

        return null;
      }

      return null;
    }).filter((item: any) => item);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: parsedData
    });
  } catch (error) {
    Notify.error(error, true);
    console.error(error);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};
