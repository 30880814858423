import { combineReducers } from "redux";
import tokenReducer from "./Token/reducer";
import registerUserReducer from "./Register/reducer";
import loginUserReducer from "./Login/reducer";
import logoutUserReducer from "./Logout/reducer";
import resetPasswordReducer from "./ResetPasword/reducer";
import resetPasswordLinkReducer from "./PasswordResetLink/reducer";
import listSubdomainsReducer from "./ListSubdomains/reducer";
import listAreaReducer from "./ListAreas/reducer";
import listCategoryReducer from "./ListCategories/reducer";
import userDetailReducer from "./User/reducer";
import createMasterplanReducer from "./CreateMasterPlan/reducer";
import selectAreaAndCategoriesReducer from "./SelectAreaAndCategories/reducer";
import prioritizeAreaAndCategoriesReducer from "./PrioritizeAreaAndCategories/reducer";
import setupMeasurementReducer from "./SetupMeasurement/reducer";
import setupGoalsReducer from "./SetupGoals/reducer";
import setupPurposeReducer from "./SetupPurpose/reducer";
import setupActionPlanReducer from "./SetupPlan/reducer";
import guestSignInReducer from "./GuestSignin/reducer";
import retrieveUserDataReducer from "./RetrieveUserData/reducer";
import RetrieveMasterPlanDetailsReducer from "./RetrieveMasterPlan/reducer";
import listMasterplanReducer from "./ListMasterplan/reducer";
import domainListReducer from "./ListDomains/reducer";
import createMasterPlanSelectJourneyReducer from "./CreateMasterPlanSelectJourney/reducer";
import updateUserAreaReducer from "./UpdateUserArea/reducer";
import deleteUserAreaReducer from "./DeleteUserArea/reducer";
import updateGoalReducer from "./UpdateGoal/reducer";
import deleteGoalReducer from "./DeleteGoal/reducer";
import updateActionReducer from "./UpdateActions/reducer";
import deleteActionReducer from "./DeleteActions/reducer";
import globalReducer from "./Global/reducer";
import createActionReducer from "./CreateAction/reducer";
import accountabilityReducer from "./ListAccountability/reducer";
import prioritizeActionPlansReducer from "./PrioritizeActionPlans/reducer";
import generateAreaCategoriesReducer from "./GenerateAreaCategory/reducer";
import requestOtpReducer from "./RequestOtp/reducer";
import verifyOtpReducer from "./VerifyOtp/reducer";
import updateUserDataReducer from "./UpdateUserData/reducer";
import UpdateTutorialProgress from "./UpdateTutorialProgress/reducer";
import getInstructionReducer from "./GetInstruction/reducer";
import getMyClientListReducer from "./MyClients/reducer";
import getProgressReducer from "./GetProgress/reducer";
import themeColorReducer from "./SchedulerTheme/reducer";
import deleteAccount from "./DeleteAccount/reducer";
import updatePassword from "./UpdatePassword/reducer";
import listMissionStatements from "./ListMissionStatements/reducer";
import getMissionStatementReducer from "./GetMissionStatement/reducer";
import createMissionStatementReducer from "./CreateMissionStatement/reducer";
import updateMissionStatementReducer from "./UpdateMissionStatement/reducer";
import deleteMissionStatementReducer from "./DeleteMissionStatement/reducer";

import predefinedMetricsReducer from "./GetPredefinedMetrics/reducer";
import customMetricsReducer from "./CustomMetrics/reducer";

import listLeverageScores from "./ListLeverageScores/reducer";


// Achievements
import listOverallProgress from "./Achievements/ListOverallProgress/reducer";
import listMasterProgress from "./Achievements/ListMasterProgress/reducer";
import listAreasProgress from "./Achievements/ListAreasProgress/reducer";
import listAreaStatuses from "./Achievements/ListAreaStatuses/reducer";
import listHistoricalData from "./Achievements/ListHistoricalData/reducer";


// Hat Services
import getQuotesReducer from "./GetQuotes/reducer";
import getRolesReducer from "./GetRoles/reducer";
import getValuesReducer from "./GetValues/reducer";

// APM
import unprioritizedReducer from './APM/UnprioritizedActions/reducer'
import updateTimeLine from './APM/UpdateTimeLine/reducer'
import weeklyDuration from './APM/WeeklyDuration/reducer'
import prioritizedActions from './APM/PrioritizedActions/reducer'
import createPrioritizedActivities from './APM/CreatePrioritizedActivities/reducer'
import availableAreas from './APM/AvailableAreas/reducer'
import updateIndirectScores from './APM/UpdateIndirectScores/reducer'
import updateFocusedPriorities from './APM/UpdateFocusedPriorities/reducer'
import prioritizedtoFocused from './APM/PrioritizedtoFocused/reducer'
import listFocusedPrioritise from './APM/ListFocusedPriorities/reducer'
import globalIdReducer from './APM/GlobalId/reducer';
import actionReducer from './APM/NewAction/reducer';
import searchCategories from './APM/SearchCategories/reducer';
import searchGoals from './APM/SearchGoals/reducer';
import listTasks from './APM/ListTasks/reducer';
import createAction from "./APM/CreateAction/reducer";
import createTask from "./APM/CreateTask/reducer";
import completeTask from "./APM/CompleteTask/reducer";

import listPendingPriorities from "./APM/ListPendingPriorities/reducer";
import listFocusedExtremes from "./APM/ListFocusedExtremes/reducer";
import listTimeDistribution from './APM/ListTimeDistribution/reducer';

export default combineReducers({
  tokenReducer,
  registerUserReducer,
  loginUserReducer,
  createMissionStatementReducer,
  updateMissionStatementReducer,
  createActionReducer,
  accountabilityReducer,
  logoutUserReducer,
  resetPasswordReducer,
  resetPasswordLinkReducer,
  listSubdomainsReducer,
  listAreaReducer,
  listCategoryReducer,
  listMissionStatements,
  getMissionStatementReducer,
  userDetailReducer,
  createMasterplanReducer,
  selectAreaAndCategoriesReducer,
  prioritizeAreaAndCategoriesReducer,
  setupMeasurementReducer,
  setupGoalsReducer,
  setupPurposeReducer,
  setupActionPlanReducer,
  guestSignInReducer,
  retrieveUserDataReducer,
  RetrieveMasterPlanDetailsReducer,
  listMasterplanReducer,
  domainListReducer,
  createMasterPlanSelectJourneyReducer,
  updateUserAreaReducer,
  deleteUserAreaReducer,
  updateGoalReducer,
  deleteGoalReducer,
  updateActionReducer,
  deleteActionReducer,
  globalReducer,
  prioritizeActionPlansReducer,
  generateAreaCategoriesReducer,
  requestOtpReducer,
  verifyOtpReducer,
  updateUserDataReducer,
  UpdateTutorialProgress,
  getInstructionReducer,
  getMyClientListReducer,
  getProgressReducer,
  themeColorReducer,
  deleteAccount,
  updatePassword,
  deleteMissionStatementReducer,

  predefinedMetricsReducer,
  customMetricsReducer,
  getRolesReducer,
  getValuesReducer,

  listLeverageScores,

  // Achievements
  listOverallProgress,
  listMasterProgress,
  listAreasProgress,
  listAreaStatuses,
  listHistoricalData,

  // Hat Services
  getQuotesReducer,

  //APM
  unprioritizedReducer,
  updateTimeLine,
  weeklyDuration,
  prioritizedActions,
  createPrioritizedActivities,
  availableAreas,
  updateIndirectScores,
  updateFocusedPriorities,
  prioritizedtoFocused,
  listFocusedPrioritise,
  globalIdReducer,
  actionReducer,
  searchCategories,
  searchGoals,
  listTasks,
  createAction,
  createTask,
  completeTask,

  listPendingPriorities,
  listFocusedExtremes,
  listTimeDistribution
});
