import { listDomain } from "api";
import { Error } from "Responses/Response";
import { ActionTypes } from "./types";
import { getGPSUserId } from "utils/utility";
import { CheckAuth } from "../Token/action";

export const ListDomain = (id = false) => async (dispatch) => {
  const userId = getGPSUserId();

  try {
    let query = "";

    if (id) {
      query = `?client=${id}`;
    }

    dispatch({ type: ActionTypes.LOADING });

    const { data } = await listDomain(query);

    if (!userId) {
      dispatch(CheckAuth({ silent: true }));
    }

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
  } catch (error) {
    Error(error?.response?.data?.error);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};

export const EmptyListDomain = (decision) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SUCCESS,
    payload: decision,
  });
};
