import React from "react";
import styled from "styled-components";

import ScreenLayout from "components/Journeys/SceneLayout";
import Achievements from "components/Achievements";

const AchievementsScreen = () => {
  return (
    <ScreenLayout secondary>
      <Wrapper>
        <PagesWrapper>
          <PageLayout>
            <Achievements />
          </PageLayout>
        </PagesWrapper>
      </Wrapper>
    </ScreenLayout>
  )
};

export default AchievementsScreen;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100vh - 60px);
  background-color: #fff;
`;

const PagesWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1439px) {
    height: calc(100vh - 118px);
  }

  ${({ theme }) => theme.max("sm")`
    height: calc(100vh - 60px);
  }`}
`;

const PageLayout = styled.div`
  flex: 1;
  overflow-y: scroll;
  position: relative;

  ${({ theme }) => theme.min("sm")`
    ::-webkit-scrollbar {
      width: 26px;
    }

    ::-webkit-scrollbar-track {
      background: transparent !important;
    }
    
    ::-webkit-scrollbar-thumb {
      border: 10px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #acb4bc;
    }
  }`}
`;
