import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 35px 48px;
  background: #fff;

  ${({ theme }) => theme.max("lg")`
    padding: 0 16px 24px 16px;
  `}
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 42px;
`;

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const Title = styled.span`
  color: #3C7588;
  font-family: Inter;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -0.9px;

  ${({ theme }) => theme.max("sm")`
    line-height: 44px;
  `}
`;

export const Text = styled.span`
  color: #7C7C7C;
  font-family: Inter;
  font-size: 18px;
  line-height: 28px;
  max-width: 612px;
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;

  ${({ theme }) => theme.max("sm")`
    flex-direction: column;
    gap: 24px;
  `}
`;

interface IButton {
  $outlined?: boolean;
}

export const Button = styled(Link)<IButton>`
  user-select: none;
  border: none;
  border-radius: 12px;
  background: #3C7588;
  padding: 13px 25px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  :focus {
    box-shadow: 0 0 0 0.25rem rgba(60, 117, 136, 0.5);
  }

  :hover {
    color: #fff;
  }

  ${({ $outlined }) => $outlined && css`
    background: transparent;
    border: 1px solid #3C7588;
    color: #3C7588;

    :hover {
      background: #3C7588;
      color: #fff;
    }
  `}
`;
