// @ts-ignore
import { BREAKPOINTS } from "theme/mixins.js";

export const options = {
  chart: {
    type: 'area' as 'area',
    stacked: false,
    width: '100%',
    height: 240,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 0,
  },
  stroke: {
    width: [2, 2],
  },
  fill: {
    type: 'gradient' as 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0,

      // Here, the default 'stops' values are commented out because the backend sometimes returns 
      // negative or unexpected values where a percentage (0-100) is expected.
      // Uncomment and adjust if backend data is fixed in the future.

      // stops: [0, 90, 100]
      stops: [-200, -100, 0]
    },
  },
  xaxis: {
    labels: {
      show: false,
    },
  },
  yaxis: {
    // The `min` and `max` values are commented out because the backend sometimes returns 
    // negative or unexpected values where a percentage (0-100) is expected.
    // Uncomment and adjust if backend data is fixed in the future.

    // min: 0,
    // max: 100,

    tickAmount: 2,
    labels: {
      align: 'left' as 'left',
      style: {
        colors: ['#6B7280'],
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: 500
      },
      offsetX: 3,
    },
  },
  grid: {
    borderColor: 'rgba(229, 231, 235, 0.50)',
    row: {
      colors: ['#fff']
    }
  },
  legend: {
    show: false
  },
  responsive: [{
    breakpoint: BREAKPOINTS.lg,
    options: {
      chart: {
        height: 220
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 2,
        labels: {
          maxWidth: 40,
          offsetX: -15,
          style: {
            fontSize: '12px',
          },
        },
      },
    },
  }],
}
