import { FC, useRef, useState } from 'react';
import { Popover } from 'antd';

import Content from './Content';

// @ts-ignore
import cogIcon from "assets/images/achievements/cog.svg";

import {
  Toggle,
  Icon
} from "./styled";

interface IData {
  id: string;
  label: string;
  goal: number;
  plan: number;
}

interface IProps {
  data: IData[];
  isJourney: boolean;
  activeItems: string[];
  maxSelection: number;
  onChange: (id: string) => void;
}

const ColumnChartSettings: FC<IProps> = ({ data, isJourney, activeItems, maxSelection, onChange }) => {
  const [open, setOpen] = useState(false);

  const $timePopover = useRef();

  const handleClose = () => {
    setOpen(false);

    if ($timePopover.current) {
      // @ts-ignore
      $timePopover.current.clear();
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const PopoverContent = () => {
    return <Content
      data={data}
      isJourney={isJourney}
      activeItems={activeItems}
      maxSelection={maxSelection}
      onChange={onChange}
      onClose={handleClose}
    />;
  };

  return (
    <Popover
      // @ts-ignore
      getPopupContainer={(trigger) => trigger.parentElement}
      align={{ offset: [16, -7] }}
      content={PopoverContent}
      title={null}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      overlayClassName="any_popover"
      overlayStyle={{ zIndex: 9999 }}
    >
      <Toggle>
        <Icon src={cogIcon} alt="" />
        <span>Settings</span>
      </Toggle>
    </Popover>
  );
}

export default ColumnChartSettings;
