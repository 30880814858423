import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Wrapper,
  Content
} from './styled';

import Header from './Header';
import OverallTrend from './OverallTrend';
import DetailedTrends from './DetailedTrends';
import Placeholder from './Placeholder';
import { DateType, getDateType } from './LineChart';

// @ts-ignore
import { ListDomain } from "Redux/ListDomains/action";

import { GetOverallProgress } from "Redux/Achievements/ListOverallProgress/action";
import { GetMasterProgress } from 'Redux/Achievements/ListMasterProgress/action';
import { GetHistoricalData } from 'Redux/Achievements/ListHistoricalData/action';

import { useQueryParams } from 'utils/useQueryParams';

interface IState {
  domainListReducer: {
    loading: boolean;
    domainList: any;
  }
}

const Achievements = () => {
  const dispatch = useDispatch();

  const { journey: journeyId, area: areaName, start, end } = useQueryParams();

  const { domainList, loading } = useSelector((state: IState) => state.domainListReducer);

  const [masterPlanId, setMasterPlanId] = useState<string | null>(null);

  useEffect(() => {
    dispatch(ListDomain());
    dispatch(GetMasterProgress());
  }, []);
  
  useEffect(() => {
    const domain = domainList.find((d: any) => d.id === journeyId);

    if (domain) {
      const masterPlanId = domain.current_state?.id;
      setMasterPlanId(masterPlanId);
    }
    else {
      setMasterPlanId(null);
    }
  }, [domainList, journeyId]);

  useEffect(() => {
    dispatch(GetOverallProgress({
      ...(masterPlanId && { domain: masterPlanId }),
      ...(areaName && { area: areaName })
    }));
  }, [masterPlanId, areaName]);

  useEffect(() => {
    const dateType = start && end ? getDateType(start, end) : DateType.WEEKLY;

    if (start && end) {
      dispatch(GetHistoricalData({
        ...(masterPlanId && { domain: masterPlanId }),
        ...(areaName && { area: areaName }),
        start_date: start,
        end_date: end,
        type: dateType,
      }));
    }
  }, [masterPlanId, areaName, start, end]);

  if (loading)
    return;

  if (!domainList.filter((d: any) => d.current_state.status !== "Not started").length)
    return <Placeholder />;

  return (
    <Wrapper>
      <Header />

      <Content>
        <OverallTrend />
        <DetailedTrends />
      </Content>
    </Wrapper>
  );
}

export default Achievements;
