import { FC } from 'react';
import { Checkbox } from "elements";

import {
  Wrapper,
  Header,
  Title,
  Text,
  Close,
  Icon,
  Body,
  Item,
} from "./styled";

// @ts-ignore
import closeIcon from "assets/images/achievements/close.svg";

interface IData {
  id: string;
  label: string;
  goal: number;
  plan: number;
}

interface IProps {
  data: IData[];
  activeItems: string[];
  maxSelection: number;
  isJourney: boolean;
  onChange: (id: string) => void;
  onClose: () => void;
}

const Content: FC<IProps> = ({ data, activeItems, maxSelection, isJourney, onChange, onClose }) => {
  const isLimitReached = activeItems.length >= maxSelection;

  const handleChange = (id: string) => () => {
    onChange(id);
  }

  return (
    <Wrapper>
      <Header>
        <Title>Settings</Title>
        <Text>Select Up to Seven {isJourney ? "Areas" : "Journeys"} to Display</Text>

        <Close onClick={onClose}>
          <Icon src={closeIcon} alt="" />
        </Close>
      </Header>

      <Body>
        {data.map(item => <Item key={item.id}>
          <Checkbox
            id={`checkbox_${item.id}`}
            checked={activeItems.includes(item.id)}
            disabled={!activeItems.includes(item.id) && isLimitReached}
            label={item.label}
            color="#4F90A6"
            onChange={handleChange(item.id)}
          />
        </Item>)}
      </Body>
    </Wrapper>
  );
}

export default Content;
