import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: calc(100vw - 500px);

  .apexcharts-xaxis-tick {
    display: none;
  }

  ${({ theme }) => theme.max("lg")`
    width: 100%;
  `}

  ${({ theme }) => theme.max("sm")`
    width: calc(100vw - 68px);
  `}
`;

export const Categories = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-left: 4.5%;
  padding-right: 1%;
  gap: 12px;

  ${({ theme }) => theme.max("sm")`
    padding-top: 0;
    margin-top: -16px;
  `}
`;

export const Category = styled.span`
  max-width: calc(100% / 7);
  color: #9CA3AF;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;

  ${({ theme }) => theme.max("sm")`
    font-size: 12px;
  `}
`;
