import {
  ChangeEvent,
  FC,
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import * as Styled from "./styled";
import ReactDOM from "react-dom";
import { Button, Checkbox, RadioButton } from "elements";

// @ts-ignore
import reloadIcon from "assets/images/reload.svg";

// @ts-ignore
import closeIcon from "assets/images/journeys/close.svg";

// @ts-ignore
import assistantBeta from "assets/images/assistant-beta.svg";

// @ts-ignore
import assistantLogo from "assets/images/assistant-logo.svg";

// @ts-ignore
import loaderIcon from "assets/images/assistant-loader.gif";

import { DataType, Item } from "./data";
import { websocketConnection } from "utils/websocket";

export interface AssistantProps {
  active: boolean;
  assistant?: {
    type: DataType;
    color?: string;
    title: string;
    order: string;
    area: string;
    category: string;
    goal?: string;
    actions?: string[];
    domain?: string;
    prompt?: string;
    onSelect?: (items: Item | Item[]) => void;
  };
  onSelect?: (item: Item | null) => void;
}

const increaseBy = (num: number) => {
  let counter = num;

  return () => {
    const result = counter;
    counter += 50;
    return result;
  };
};

function randomID(): string {
  return `id_` + Math.floor(10000 + Math.random() * 90000);
}

interface ILoader {
  title: string;
  loading: boolean;
  onRefresh: () => void;
}

const Loader: FC<ILoader> = ({ title, loading, onRefresh }) => {
  const [isRefreshVisible, setRefreshVisible] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const animateDelayCounter = increaseBy(100);

  const showRefreshButton = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => setRefreshVisible(true), 5000);
  };

  useEffect(() => {
    if (loading) {
      setRefreshVisible(false);
      showRefreshButton();
    }
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [loading]);

  const handleClick = () => {
    setRefreshVisible(false);
    onRefresh();
    showRefreshButton();
  };

  return (
    <Styled.Loader>
      <Styled.LoaderImg
        src={assistantLogo}
        width={69}
        height={69}
        $animate={loading}
        $animateDelay={animateDelayCounter()}
        alt=""
      />

      <Styled.LoaderText
        $animate={loading}
        $animateDelay={animateDelayCounter()}
      >
        Suggesting {title}...
      </Styled.LoaderText>

      <Styled.LoaderImg
        src={loaderIcon}
        width={52}
        height={53}
        $animate={loading}
        $animateDelay={animateDelayCounter()}
        alt="Loading"
      />

      <Styled.LoaderFooter $animate={isRefreshVisible}>
        <Button onClick={handleClick} styleType="secondary">
          <img src={reloadIcon} width={16} height={16} alt="Reload" />
          Refresh
        </Button>
      </Styled.LoaderFooter>
    </Styled.Loader>
  );
};

const Assistant: FC<AssistantProps> = ({
  active,
  assistant: data,
  onSelect,
}) => {
  const [list, setList] = useState<Item[]>([]);

  const [selected, setSelected] = useState<string | string[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [error, setError] = useState(false);

  const [shouldRender, setShouldRender] = useState(active);
  const [animationActive, setAnimationActive] = useState(false);

  const $scrollable = createRef<HTMLDivElement>();
  const $wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!active) return;

    websocketConnection.connect(handleSuggest, handleError, handleErrorClose);
    websocketConnection.setMessageCallback(handleMessage);

    return () => {
      websocketConnection.close();
    };
  }, [active]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (active) {
      setShouldRender(true);
      setLoading(true);
      setError(false);

      timeoutId = setTimeout(() => {
        setAnimationActive(true);
      }, 100);
    } else {
      setAnimationActive(false);

      timeoutId = setTimeout(() => {
        setShouldRender(false);
      }, 350);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [active]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const checkForScrollbar = () => {
      if ($scrollable.current && animationActive) {
        const hasScrollbar =
          $scrollable.current.scrollHeight > $scrollable.current.clientHeight;
        setHasScrollbar(hasScrollbar);
      }
    };

    if (animationActive) timeoutId = setTimeout(checkForScrollbar, 2000);

    window.addEventListener("resize", checkForScrollbar);

    return () => {
      window.removeEventListener("resize", checkForScrollbar);
      clearTimeout(timeoutId);
    };
  }, [$scrollable, animationActive]);

  const handleSuggest = () => {
    const endpointMapping: Record<DataType, string> = {
      [DataType.GOAL]: "suggest_goals",
      [DataType.MEASUREMENT]: "suggest_measurements",
      [DataType.REWARD]: "suggest_rewards",
      [DataType.CONSEQUENCE]: "suggest_consequences",
      [DataType.ACTION]: "suggest_action_items",
    };

    const getEndpoint = () => {
      const baseData = {
        category: data?.area,
        subcategory: data?.category,
        input_text: data?.prompt
      };

      if (!data?.type)
        return;

      const endpoint = endpointMapping[data?.type];
      const additionalData =
        data?.type === DataType.GOAL
          ? {}
          : data?.type === DataType.ACTION
            ? { goal: data?.goal, domain_id: data?.domain, action_items: data?.actions, regenerate: true }
            : { goal: data?.goal };

      return {
        endpoint,
        data: { ...baseData, ...additionalData },
      };
    };

    if (!data?.type)
      return;

    websocketConnection.send(JSON.stringify(getEndpoint()));
  };

  const handleError = () => {
    handleRegenerate();
  }

  const handleErrorClose = () => {
    if (loading) {
      handleRegenerate();
    }
  }

  const handleMessage = (message: string) => {
    const res = JSON.parse(message);

    if (res.ping || res.pong)
      return;

    const processItems = (items: string[]) => {
      if (data?.type === DataType.ACTION) {
        const parseType = (type: string) => {
          if (type === "action") return "Action Item";
          else if (type === "decision") return "Decision";
          else if (type === "habit") return "Habit";
        };

        return items.map(
          (item: any) =>
            ({
              id: randomID(),
              text: item?.actionItem,
              accountability: item?.accountability,
              type: parseType(item?.type),
            } as Item)
        );
      } else if (data?.type === DataType.MEASUREMENT) {
        return items.map(
          (item: any) =>
            ({
              id: randomID(),
              text: item?.Unit,
              value: item?.Target,
              label: item?.Label,
            } as Item)
        );
      }

      return items.map(
        (item: string) => ({ id: randomID(), text: item } as Item)
      );
    };

    const updateStateWithItems = (items: Item[]) => {
      if (items.length) {
        setList(items);
        setLoading(false);

        if (data?.type === DataType.ACTION) {
          setSelected([]);
        } else {
          setSelected(items[0].id);
        }
      }
    };

    if (res?.message && data?.type) {
      const itemsMap: Record<DataType, string[]> = {
        [DataType.GOAL]: res.message.goals,
        [DataType.MEASUREMENT]: res.message.measurements,
        [DataType.REWARD]: res.message.positive_impacts,
        [DataType.CONSEQUENCE]: res.message.consequences,
        [DataType.ACTION]: res.message.actionItems,
      };

      const items = itemsMap[data.type];

      if (items) {
        const response: Item[] = processItems(items);
        updateStateWithItems(response);
      }
    }
  };

  const handleClose = () => {
    onSelect?.(null);
  };

  const handleClickOutside = useCallback(
    (event: Event) => {
      if (
        $wrapperRef.current &&
        !$wrapperRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    // The listener is added with the function reference.
    document.addEventListener("mouseup", handleClickOutside as EventListener);

    return () => {
      // The listener is removed with the same function reference.
      document.removeEventListener(
        "mouseup",
        handleClickOutside as EventListener
      );
    };
  }, [handleClickOutside]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const id = event.target.id;

    setSelected((prevSelected) => {
      if (!prevSelected || typeof prevSelected === "string") return [id];
      return prevSelected.includes(id)
        ? prevSelected.filter((item) => item !== id)
        : [...prevSelected, id];
    });
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.id);
  };

  const handleRegenerate = () => {
    setLoading(true);

    if (error) {
      websocketConnection.connect(handleSuggest, handleError);
      setError(false);
      setList([]);
    }
    else {
      handleSuggest();
    }
  };

  const handleRefresh = () => {
    websocketConnection.close();
    websocketConnection.connect(handleSuggest, handleError);
  }

  const handleApply = () => {
    if (Array.isArray(selected)) {
      const items = list.filter((item) => selected.includes(item.id));

      onSelect?.(items[0]);
      data?.onSelect?.(items);
    } else {
      const item = list.find((item) => item.id === selected);

      if (item) {
        if (data?.type === DataType.MEASUREMENT) {
          data?.onSelect?.(item);
          onSelect?.(null);
        } else {
          onSelect?.(item);
        }
      }
    }
  };

  const renderError = () => {
    const animateDelayCounter = increaseBy(100);

    return (
      <Styled.Loader>
        <Styled.LoaderText
          $animate={error}
          $animateDelay={animateDelayCounter()}
        >
          Error connecting to AI assistant
        </Styled.LoaderText>

        <Button onClick={handleRegenerate} styleType="link-primary">
          <img src={reloadIcon} width={16} height={16} alt="Reload" />
          Regenerate
        </Button>
      </Styled.Loader>
    );
  }

  const renderList = () => {
    const animateDelayCounter = increaseBy(100);

    return (
      <>
        <Styled.Scrollable ref={$scrollable} $hasScrollbar={hasScrollbar}>
          <Styled.List>
            {list.map((item) => {
              const renderLabel = () => {
                if (data?.type === DataType.ACTION) {
                  return (
                    <Styled.LiContent $type={DataType.ACTION}>
                      <span>
                        <b>Action Item:</b> {item.text}
                      </span>
                      <span>
                        <b>Accountability:</b> {item.accountability}
                      </span>
                      <span>
                        <b>Type:</b> {item.type}
                      </span>
                    </Styled.LiContent>
                  );
                }
                else if (data?.type === DataType.MEASUREMENT) {
                  return (
                    <Styled.LiContent $type={DataType.MEASUREMENT}>
                      <span>{item.label}</span>
                      <span>{item.text}</span>
                      <span>{item.value}</span>
                    </Styled.LiContent>
                  );
                }
                return (
                  <Styled.LiContent>
                    <span>{item.text}</span>
                    <span>{item.value}</span>
                  </Styled.LiContent>
                );
              };

              const renderToggle = () => {
                if (data?.type === DataType.ACTION) {
                  return (
                    <Checkbox
                      id={item.id}
                      checked={
                        selected && Array.isArray(selected)
                          ? selected.includes(item.id)
                          : false
                      }
                      label={renderLabel()}
                      rtl
                      width="100%"
                      onChange={handleChange}
                    />
                  );
                }

                return (
                  <RadioButton
                    id={item.id}
                    checked={selected === item.id}
                    label={renderLabel()}
                    name="assistant"
                    rtl
                    width="100%"
                    onChange={handleRadioChange}
                    styles={{
                      label: {
                        alignItems: 'center'
                      }
                    }}
                  />
                );
              };

              return (
                <Styled.ListItem
                  $active={selected === item.id}
                  $animate={!loading}
                  $animateDelay={animateDelayCounter()}
                  key={item.id}
                >
                  {renderToggle()}
                </Styled.ListItem>
              );
            })}
          </Styled.List>
        </Styled.Scrollable>

        <Styled.Footer
          $animate={!loading}
          $animateDelay={animateDelayCounter()}
        >
          <Button onClick={handleRegenerate} styleType="link-primary">
            <img src={reloadIcon} width={16} height={16} alt="Reload" />
            Regenerate
          </Button>
          <Button
            disabled={
              !selected || (Array.isArray(selected) && !selected.length)
            }
            onClick={handleApply}
          >
            Apply
          </Button>
        </Styled.Footer>
      </>
    );
  };

  if (!shouldRender) return null;

  return ReactDOM.createPortal(
    <Styled.Wrapper
      ref={$wrapperRef}
      $color={data?.color}
      $active={animationActive}
    >
      <Styled.Header>
        <Styled.Title>{data?.title}</Styled.Title>
        <Styled.ToggleClose type="button" onClick={handleClose}>
          <img src={closeIcon} width={24} height={24} alt="Close" />
        </Styled.ToggleClose>
      </Styled.Header>

      <Styled.Details $mb={data?.type === DataType.MEASUREMENT ? 6 : 18}>
        <Styled.Order>{data?.order}</Styled.Order>
        <Styled.DetailsWrap>
          <Styled.Area title={data?.area}>{data?.area}</Styled.Area>
          <Styled.Category title={data?.category}>
            {data?.category}
          </Styled.Category>
        </Styled.DetailsWrap>
      </Styled.Details>

      {data?.type === DataType.MEASUREMENT && !loading && !error && (
        <Styled.Measurement>Label / Unit / Target</Styled.Measurement>
      )}

      <Styled.Content>{error
        ? renderError()
        : (loading
          ? <Loader title={data?.title || ''} loading={loading} onRefresh={handleRefresh} />
          : renderList())}
      </Styled.Content>

      <Styled.Bottom>
        {data?.type === DataType.ACTION && (
          <Styled.Disclaimer>
            NOTE: Select which actions to add to your goal. If more actions are selected than the default items, new actions will be created automatically.
          </Styled.Disclaimer>
        )}

        <img
          src={assistantBeta}
          width={144}
          height={24}
          alt="AI Assistant Beta"
        />
      </Styled.Bottom>
    </Styled.Wrapper>,
    document.getElementById("portal-root") as Element
  );
};

export default Assistant;
