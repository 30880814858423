import styled, { css } from 'styled-components';

export const Toggle = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  color: #6B7280;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 125%;
  background: transparent;
  border: none;
`;

export const Icon = styled.img`
  width: 14px;
`;
