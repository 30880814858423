import { FC, useEffect, useState } from 'react';
import ReactApexChart from "react-apexcharts";
import moment from 'moment';

import {
  Wrapper,

  Categories,
  Category,
} from './styled';

import { options } from './chart';

import type { IHistoricalData } from '@types';

import { useQueryParams } from 'utils/useQueryParams';

interface IDataState {
  name: string;
  data: number[];
}

interface IProps {
  data: IHistoricalData[];
}

export enum DateType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly'
}

export function getDateType(startDate: string | Date, endDate: string | Date): DateType {
  const start = moment(startDate);
  const end = moment(endDate);

  const daysDiff = end.diff(start, 'days');
  const monthsDiff = end.diff(start, 'months');
  const yearsDiff = end.diff(start, 'years');

  if (daysDiff <= 7) {
    return DateType.DAILY;
  } else if (monthsDiff < 2) {
    return DateType.WEEKLY;
  } else if (yearsDiff < 1) {
    return DateType.MONTHLY;
  } else {
    return DateType.YEARLY;
  }
}

const LineChart: FC<IProps> = ({ data }) => {
  const { journey } = useQueryParams();

  const [state, setState] = useState<IDataState[]>([
    {
      name: 'Plan Progress',
      data: []
    }, {
      name: 'Goal Progress',
      data: []
    }
  ]);

  useEffect(() => {
    setState([
      {
        name: 'Plan Progress',
        data: data.map((item) => item.action_value)
      }, {
        name: 'Goal Progress',
        data: data.map((item) => item.goal_value)
      }
    ]);
  }, [data]);

  const categories = data.map((item) => moment(item.date).format('MMM DD'));

  const chartOptions = {
    ...options,
    xaxis: {
      ...options.xaxis,
      categories
    },
    colors: journey ? ['#7400CF', '#17C1D9'] : ['#E88614', '#17C1D9'],
  }

  const chartData = [
    {
      name: 'Plan Progress',
      data: state[0].data
    }, {
      name: 'Goal Progress',
      data: state[1].data
    }
  ];

  return (
    <Wrapper>
      <ReactApexChart
        options={chartOptions}
        series={chartData}
        type="area"
        width="100%"
        height={240}
      />

      <Categories>
        {categories.map((item, index) => (
          <Category key={index}>
            {item}
          </Category>
        ))}
      </Categories>
    </Wrapper>
  );
}

export default LineChart;
