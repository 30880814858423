import styled from "styled-components";
import { FC, useState } from 'react';
import { DatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import en_US from "antd/es/date-picker/locale/en_US";
import moment from 'moment';

// @ts-ignore
// import chevronRightIcon from "assets/images/journeys/chevron-right.svg";

// @ts-ignore
// import calendarDarkIcon from "assets/images/journeys/calendar-dark.svg";

// @ts-ignore
import styles from './styles.module.scss';

import {
  DropdownToggler,
  Wrapper
} from "./styled";

const { RangePicker } = DatePicker;

interface IProps {
  value: RangePickerProps['value'];
  onChange: RangePickerProps['onChange'];
}

const locale = {
  ...en_US,
  lang: {
    ...en_US.lang,
    monthFormat: "MMMM"
  }
};

const CalendarWidget: FC<IProps> = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 50);
  };

  const dateRanges = {
    /* 'Today': [
      moment().startOf('day'),
      moment().endOf('day')
    ], */
    'This Week': [
      moment().startOf('week'),
      moment().endOf('week')
    ],
    'This Month': [
      moment().startOf('month'),
      moment().endOf('month')
    ],
    'Last 3 Months': [
      moment().subtract(3, 'months').startOf('month'), 
      moment().endOf('month')
    ],
    'Last 6 Months': [
      moment().subtract(6, 'months').startOf('month'), 
      moment().endOf('month')
    ],
    'This Year': [
      moment().startOf('year'),
      moment().endOf('year')
    ]
  };

  const getLabel = () => {
    if (!value)
      return 'Select';

    for (const [label, range] of Object.entries(dateRanges)) {
      if (moment(value[0]).isSame(range[0], 'day') && moment(value[1]).isSame(range[1], 'day')) {
        return label;
      }
    }

    // Format as "DD MMM - DD MMM" if no predefined range matches
    return `${moment(value[0]).format('DD MMM')} - ${moment(value[1]).format('DD MMM')}`;
  };

  return (
    <Wrapper>
      <DropdownToggler onClick={handleOpen}>
        {/* <img src={calendarDarkIcon} alt="" /> */}
        {getLabel()}
        {/* <img src={chevronRightIcon} alt="" /> */}
      </DropdownToggler>

      <RangePicker
        open={isOpen}
        locale={locale}
        // @ts-ignore
        ranges={dateRanges}
        className={styles.input}
        popupClassName={styles.popup}
        placement="bottomRight"
        onChange={onChange}
        onOpenChange={handleClose}
      />
    </Wrapper>
  );
};


export default CalendarWidget;
