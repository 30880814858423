import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  max-width: 180px;

  ${({ theme }) => theme.max("sm")`
    max-width: initial;
    width: 100%;
  `}
`;

export const DropdownToggler = styled.button<{ $disabled?: boolean; $empty?: boolean }>`
  width: 292px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
  color: var(--Stroke-Text-Body-Prussian-blue, #1B2A3D);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: box-shadow .3s;
  outline: none;
  padding: 7px 12px;
  border-radius: 8px;
  border: 1px solid #D1D5DB;
  background: var(--Dark-mode-bg, #FFF);

  img:last-of-type {
    transform: rotate(90deg);
  }

  ${({ $disabled }) => !$disabled && `
    &:focus,
    &:active {
      box-shadow: 0px 0px 0px 2px #D0E3ED;
    }
  `}

  ${({ $disabled }) => $disabled && `
    cursor: default;
  `}

  ${({ $empty }) => $empty && `
    color: #A3A3A3;
  `}

  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}
`;
