import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;

  ${({ theme }) => theme.max("lg")`
    flex-direction: column;
    padding: 0 35px;
  `}

  ${({ theme }) => theme.max("sm")`
    padding: 0 20px;
  `}
`;

export const Content = styled.div`
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  ${({ theme }) => theme.max("sm")`
    margin-bottom: 16px;
  `}
`;

interface ICol {
  $right?: boolean;
}

export const Col = styled.div<ICol>`
  display: flex;
  flex-direction: column;

  ${({ $right }) => $right && css`
    align-items: flex-end;
  `}
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Icon = styled.img``;

export const Title = styled.span`
  color: #232323;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;

  ${({ theme }) => theme.max("sm")`
    font-size: 16px;
  `}
`;

export const Text = styled.span`
  color: #6B7280;
  font-family: Inter;
  font-size: 14px;
  line-height: 150%;

  ${({ theme }) => theme.max("sm")`
    font-size: 12px;
  `}
`;

export const SuccessValue = styled.span`
  color: #58B039;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;

  ${({ theme }) => theme.max("sm")`
    font-size: 16px;
  `}
`;

export const SuccessText = styled.span`
  color: #767A85;
  font-family: Inter;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.28px;

  ${({ theme }) => theme.max("sm")`
    font-size: 12px;
    letter-spacing: -0.24px;
  `}
`;

export const Side = styled.div`
  width: 320px;

  ${({ theme }) => theme.max("lg")`
    display: flex;
    width: initial;
  `}
`;

export const SideHr = styled.div`
  display: flex;
  margin: 20px 0;
  padding: 0 16px;

  ::before {
    content: '';
    background: rgba(229, 231, 235, 0.50);
    width: 100%;
    height: 1px;
  }
`;
