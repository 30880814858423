import React, { useEffect, useRef } from 'react';
import { Popover } from 'antd';

import FilterContent from './FilterContent';

// @ts-ignore
import arrowDownIcon from "assets/images/achievements/arrow-down.svg";

import {
  DropdownToggle,
  Icon
} from "./styled";

import { useQueryParams } from 'utils/useQueryParams';
import { useSelector } from 'react-redux';

interface IState {
  domainListReducer: {
    domainList: any;
  }
}

const FilterWidget = () => {
  const { journey, area } = useQueryParams();

  const { domainList } = useSelector((state: IState) => state.domainListReducer);

  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState("All Journeys");

  const $timePopover = useRef();

  useEffect(() => {
    if (journey) {
      const domain = domainList.find((d: any) => d.id === journey);

      if (domain) {
        setItem(domain.name);
      } else {
        setItem("All Journeys");
      }
    }
    else {
      setItem("All Journeys");
    }
  }, [journey, domainList]);

  const handleClose = () => {
    setOpen(false);

    if ($timePopover.current) {
      // @ts-ignore
      $timePopover.current.clear();
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const PopoverContent = () => {
    return <FilterContent
      onClose={handleClose}
    />;
  };

  return (
    <Popover
      // @ts-ignore
      getPopupContainer={(trigger) => trigger.parentNode}
      align={{ offset: [0, -7] }}
      content={PopoverContent}
      title={null}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomRight"
      overlayClassName="any_popover"
      overlayStyle={{ zIndex: 9999 }}
    >
      <DropdownToggle>
        <span>{item} {area ? ` + ${area}` : null}</span>
        <Icon src={arrowDownIcon} alt="" />
      </DropdownToggle>
    </Popover>
  );
}

export default FilterWidget;
