import { JOURNEY_PATH } from "utils/routes";

import {
  Wrapper,
  Content,
  Title,
  Text,
  Head,
  Body,
  Button
} from "./styled";

const Placeholder = () => {
  return (
    <Wrapper>
      <Content>
        <Head>
          <Title>Start Your Journey to Achievement</Title>
          <Text>Track your progress and celebrate victories across all areas of life. Begin by setting up your first goal or exploring journey templates.</Text>
        </Head>
        <Body>
          <Button to={JOURNEY_PATH}>Go to Journeys</Button>
        </Body>
      </Content>
    </Wrapper>
  )
}

export default Placeholder;
