import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ColumnChart from '../ColumnChart';
import JourneyDetails from '../JourneyDetails';
import AreaDetails from '../AreaDetails';
import DetailsWrapper from '../DetailsWrapper';
import ColumnChartSettings from '../ColumnChartSettings';

import {
  Wrapper,
  Content,

  Header,
  Title,

  Legend,
  LegendItem,
  
  Side
} from './styled';

import { useQueryParams } from 'utils/useQueryParams';

import { GetAreasProgress } from 'Redux/Achievements/ListAreasProgress/action';

import { IState } from '@types';

const MAX_SELECTION = 7;

const DetailedTrends = () => {
  const dispatch = useDispatch();
  const { journey } = useQueryParams();

  const { domainList } = useSelector((state: IState) => state.domainListReducer);
  const { data: journeysProgress } = useSelector((state: IState) => state.listMasterProgress);
  const { data: areasProgress } = useSelector((state: IState) => state.listAreasProgress);

  const [masterPlanId, setMasterPlanId] = useState<string | null>(null);
  const [activeItems, setActiveItems] = useState<string[]>([]);

  useEffect(() => {
    const domain = domainList.find((d: any) => d.id === journey);

    if (domain) {
      const masterPlanId = domain.current_state?.id;
      setMasterPlanId(masterPlanId);
    };
  }, [domainList, journey]);

  useEffect(() => {
    if (masterPlanId) {
      dispatch(GetAreasProgress({
        domain: masterPlanId
      }));
    }
  }, [masterPlanId]);

  const data = journey
    ? areasProgress.map((item) => ({
        id: item.user_area_name,
        label: item.user_area_name,
        goal: item.goal_progress,
        plan: item.plan_progress
    }))
    : journeysProgress.map((item) => ({
        id: item.masterplan_id,
        label: item.masterplan_name,
        goal: item.goal_progress,
        plan: item.plan_progress
    }))
    .filter((item, index, self) => 
      self.findIndex(i => i.label === item.label) === index
    );

  useEffect(() => {
    setActiveItems(data.slice(0, MAX_SELECTION).map(item => item.id));
  }, [JSON.stringify(data)]);

  const filteredData = data.filter(item => activeItems.includes(item.id));

  const handleChange = (id: string) => {
    setActiveItems(prevItems => {
      if (prevItems.includes(id)) {
        return prevItems.filter(item => item !== id);
      } else {
        return [...prevItems, id];
      }
    });
  }

  return (
    <Wrapper>
      <Content>
        <Header>
          <Title>{journey ? "Area" : "Journey"} Trends</Title>

          <Legend>
            <LegendItem $goal $alt={!!journey}>Goal Completion Rate</LegendItem>
            <LegendItem $plan $alt={!!journey}>Plan Progress</LegendItem>
          </Legend>

          <ColumnChartSettings
            data={data}
            activeItems={activeItems}
            maxSelection={MAX_SELECTION}
            isJourney={!!journey}
            onChange={handleChange}
          />
        </Header>

        <ColumnChart data={filteredData} />
      </Content>

      <Side>
        <DetailsWrapper data={filteredData}>
          {journey
            ? <AreaDetails data={data} masterPlanId={masterPlanId} />
            : <JourneyDetails data={filteredData} />}
        </DetailsWrapper>
      </Side>
    </Wrapper>
  );
}

export default DetailedTrends;
