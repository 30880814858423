import React, { useEffect, useState, useRef } from "react";
import { Col, Modal, Drawer } from "antd";
import { Button, Tooltip, Spinner } from "elements";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';

import useDeviceType from "../../../../Hooks/ResponsiveHook";

import { ReactComponent as Tooltipicon } from "./tooltip.svg";

import StatmentIcon from "assets/svg/statmentIcon.svg";
import StatmentGrayIcon from "assets/svg/statmentGrayIcon.svg";
import MenuExpandRight from "assets/svg/MenuCollapseLeft.svg";
import MenuExpandLeft from "assets/svg/MenuExpandLeft.svg";
import ProgressIcon from "assets/svg/InProgress.svg";

import Steps from "./components/Steps";
import PrintHead from './components/PrintHead';
import AIHelper from "./components/AIHelper";

import MissionButton from "atoms/MissionElement/Button";
import MissionSelect, { getOptions } from "atoms/MissionElement/MissionSelect";

import EditableInput from "atoms/MissionElement/Input";
import MissionDropDown from "atoms/MissionElement/MissionDropDown/MissionDropDown";
import DeleteModal from "atoms/MissionElement/DeleteModal/DeleteModal";

import {
  GetMissionStatement,
  SetMissionStatement,
} from "Redux/GetMissionStatement/action";
import {
  MissionStatement,
  SetCreateMissionStatement,
} from "Redux/CreateMissionStatement/action";
import {
  SetUpdateMissionStatement,
  UpdateMissionStatement,
} from "Redux/UpdateMissionStatement/action";
import { DeleteRoles } from "Redux/DeleteRoles/action";
import { DeleteValues } from "Redux/DeleteValues/action";
import { DeleteMissionStatement } from "Redux/DeleteMissionStatement/action";

import { GetRoles, CreateCustomRole } from "Redux/GetRoles/action";
import { GetValues, CreateCustomValue } from "Redux/GetValues/action";

import ExitConfirmModal from "atoms/MissionElement/ExitConfirmModal";

import { getCookie } from "utils/StorageVariables";

import {
  Container,
  Content,
  ModalHeader,
  Header,
  PlaceholderColumn,
  Image,
  Heading,
  StatmentContent,
  LargeHeading,
  Text,
  RolesContainer,
  RolesContent,
  BodyWrapper,
  MainRoleDiv,
  MissionArea,
  StepsWrapper,
  TextsWrapper,
  XlargeHeading,
  RolesText,
  SmallText,
  SelectWrapper,
  InputWrapper,
  AiSugesstionText,
  Circle,
  RolesHeading,
  MissionHeading,
  RoleHeading,
  RolesSpan,
  DropdownWrapper,
  Wrapper,
  CustomTable,
  CustomRow,
  RowHeading,
  RowText,
  MissionComplete,
  RoleAI,
  StatementsProcess,
  StatementsProgress,
  MainRoleWrapper,
  DrawerHeader
} from "./styled";

import styles from "./styles.module.scss";

import { items } from "./data";

const isUUID = (value) => {
  const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return regex.test(value);
}

const getArticle = (word) => {
  if (!word || typeof word !== "string")
    return "";

  // Check the first letter or sound
  const vowels = ['a', 'e', 'i', 'o', 'u'];
  const firstLetter = word[0].toLowerCase();

  return vowels.includes(firstLetter) ? "an" : "a";
}

const MissionStatment = ({
  setisShow,
  missionStatmentGoal,
  setMissionStatementGoal,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  let domainName = getCookie("domainName");
  const screenSizes = useDeviceType();

  const isMobile = screenSizes === "mobile";
  const isTablet = screenSizes === "iPad";

  const { data: roleOption } = useSelector((state) => state.getRolesReducer);
  const { data: valueOption } = useSelector((state) => state.getValuesReducer);
  
  const modalStyles =
    isMobile || isTablet
      ? {
          top: 0,
          left: 0,
          padding: 0,
          margin: 0,
          height: "auto",
          minHeight: "100vh",
          // overflow: "scroll",
          width: "100vw",
          maxWidth: "100vw",
          position: "fixed",
        }
      : { maxHeight: "795px" };

  const modalBodyStyles =
    isMobile || isTablet
      ? { padding: 0, margin: 0, height: "100%", width: "100%" }
      : { padding: 0, height: "100%", overflow: "hidden" };

  const modalContentStyles =
    isMobile || isTablet
      ? { height: "auto", minHeight: "100%", overflow: "scroll" }
      : { height: "100%" };

  useEffect(() => {
    dispatch(GetRoles());
  }, []);

  const [currentStep, setCurrentStep] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmExit, setIsConfirmExit] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [deleteOpenModal, setDeleteOpenModal] = useState(false);
  const [missionStatementData, setMissionStatementData] = useState({
    roles: [],
    values: []
  });

  const [rolesToDelete, setRolesToDelete] = useState([]);
  const [valuesToDelete, setValuesToDelete] = useState([]);
  const [showDrawerProgress, setShowDrawerProgress] = useState(false);
  const [showDrawerAI, setShowDrawerAI] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const { loading: deleteMissionloading } = useSelector(
    (state) => state.deleteMissionStatementReducer
  );

  const { missionStatement, loading: getMissionStatementLoading } = useSelector(
    (state) => state.getMissionStatementReducer
  );

  const { loading } = useSelector(
    (state) => state.createMissionStatementReducer
  );

  const { loading: updateMissionStatementLoading } = useSelector(
    (state) => state.updateMissionStatementReducer
  );

  const $componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: "Mission Statement",
    content: () => $componentRef.current,
  });

  useEffect(() => {
    if (!missionStatmentGoal) return;
    if (missionStatmentGoal?.hasMissionStatement) {
      dispatch(GetMissionStatement(missionStatmentGoal?.goal));
    }
  }, [missionStatmentGoal]);

  useEffect(() => {
    return () => {
      dispatch(SetMissionStatement(null));
      dispatch(SetUpdateMissionStatement(null));
      dispatch(SetCreateMissionStatement(null));
    };
  }, []);

  useEffect(() => {
    setisLoading(
      loading || getMissionStatementLoading || updateMissionStatementLoading
    );
  }, [loading, getMissionStatementLoading, updateMissionStatementLoading]);

  useEffect(() => {
    if (currentStep === 2)
      dispatch(GetValues(missionStatementData.roles.map(({ value }) => value)));
  }, [missionStatementData.roles.map(({ value }) => value).join(","), currentStep]);

  const roles = roleOption;
  const values = valueOption;

  useEffect(() => {
    if (!missionStatement) return;

    const missionStatementCopy = { ...missionStatement };

    setMissionStatementData(() => ({
      ...missionStatementCopy,
      roles: missionStatementCopy?.roles.filter(item => !rolesToDelete.includes(item.id)).map((item) => {
        return {
          value: item.id,
          id: item.id,
          label: item.name,
        };
      }),
      values: missionStatementCopy?.values.filter(item => !valuesToDelete.includes(item.id)).map((item) => {
        return {
          value: item.id,
          id: item.id,
          label: item.name,
        };
      }),
    }));
  }, [missionStatement?.toString(), /* roles */]);

  const handleStepChange = (step) => {
    setCurrentStep(step);

    if (isMobile) {
      closeDrawerProgress();
    }
  };

  const handleChange = (val, step, applyPlaceholder) => {
    const value = val.slice(0, 255);

    if (value.length > 255) {
      setErrorMessage("Character limit reached.");
    } else {
      setErrorMessage(""); // Clear the error message if the input is valid
    }

    const roleValue = missionStatementData['roles'] && missionStatementData['roles'][0] ? missionStatementData['roles'][0].label.toLowerCase() : '';

    switch (step) {
      case 3:
        setMissionStatementData((prevData) => ({
          ...prevData,
          principle: { ...prevData?.principle, name: !prevData?.principle && applyPlaceholder ? `${items[2].placeholder.replace('{role}', `${getArticle(roleValue)} ${roleValue}`)} ${value}` : value },
        }));
        break;
      case 4:
        setMissionStatementData((prevData) => ({
          ...prevData,
          code_of_conduct: { ...prevData?.code_of_conduct, name: !prevData?.code_of_conduct && applyPlaceholder ? `${items[3].placeholder} ${value}` : value },
        }));
        break;
      case 5:
        setMissionStatementData((prevData) => ({
          ...prevData,
          purpose: { ...prevData?.purpose, name: !prevData?.purpose && applyPlaceholder ? `${items[4].placeholder} ${value}` : value },
        }));
        break;
      case 6:
        setMissionStatementData((prevData) => ({
          ...prevData,
          vision: { ...prevData?.vision, name: !prevData?.vision && applyPlaceholder ? `${items[5].placeholder} ${value}` : value },
        }));
        break;

      default:
        break;
    }
  };

  const nextStep = (item) => {
    const nextStep = currentStep + 1;

    if (nextStep <= items?.length) {
      handleStepChange(nextStep);
    }
  };

  const handleSave = async (item, shouldClose = true) => {
    if (item?.step < 6) return nextStep(item);

    if (item?.step === 6) {
      const newRoles = missionStatementData?.roles?.filter(
        (role) => role.isNew
      );
      const newValues = missionStatementData?.values?.filter(
        (value) => value.isNew
      );

      const rolesToSave = newRoles.map(({ label }) => ({ name: label }));
      const valuesToSave = newValues.map(({ label }) => ({ name: label }));

      // setIsEdit(item?.title);

      setisLoading(true);

      if (missionStatmentGoal?.hasMissionStatement) {
        await onDelete();

        return dispatch(
          UpdateMissionStatement(
            {
              ...missionStatementData,
              roles: rolesToSave,
              values: valuesToSave,
              goal: missionStatmentGoal?.goal,
            },
            missionStatementData?.id,
            () => {
              setCurrentStep(7);
              setisLoading(false);
            }
          )
        );
      } else {
        return dispatch(
          MissionStatement(
            {
              ...missionStatementData,
              roles: rolesToSave,
              values: valuesToSave,
              goal: missionStatmentGoal?.goal,
            },
            setMissionStatementGoal,
            missionStatmentGoal,
            () => {
              setCurrentStep(7);
              setisLoading(false);
            }
          )
        );
      }
    }

    if (shouldClose) return handleClose();
  };

  const onDelete = async () => {
    const deleteRolesPromises = rolesToDelete.map((role) => {
      return new Promise((resolve, reject) => {
        dispatch(
          DeleteRoles(role, () => {
            resolve(true);
          })
        );
      });
    });

    const deleteValuesPromises = valuesToDelete.map((value) => {
      return new Promise((resolve, reject) => {
        dispatch(
          DeleteValues(value, () => {
            resolve(true);
          })
        );
      });
    });

    try {
      await Promise.all(deleteRolesPromises);
      await Promise.all(deleteValuesPromises);

      setRolesToDelete([]);
      setValuesToDelete([]);

      return true;
    } catch (error) {
      console.error("Error deleting roles:", error);
      return false;
    }
  };

  const handleSelect = (key, value) => {
    if (value && key === "roles") {
      const roleData = roles.find((role) => role.value === value);

      setMissionStatementData((prevData) => ({
        ...prevData,
        roles: [...prevData.roles, { ...roleData, isNew: true }],
      }));
    } else if (value && key === "values") {
      const valueData = values.find((val) => val.value === value);

      setMissionStatementData((prevData) => ({
        ...prevData,
        values: [...prevData.values, { ...valueData, isNew: true }],
      }));
    }
  };

  const handleAISelect = (values, step) => {
    const items = values.map((value) => ({ label: value.text, value: value.text }));
  
    if (step > 2) {
      values.forEach((value) => handleChange(value.text, step, false));
    } else {
      const type = step === 1 ? "roles" : "values";
  
      setMissionStatementData((prevData) => {
        const updatedData = { ...prevData };
  
        items.forEach((item) => {
          const existingItem =
            roleOption.find((i) => i.label === item.label) ||
            valueOption.find((i) => i.label === item.label);
          const exists = updatedData[type].some((i) => i.label === item.label);
  
          if (!exists) {
            // Only add if it doesn't already exist
            updatedData[type] = [
              ...updatedData[type],
              {
                ...(existingItem ? existingItem : item),
                isNew: true,
              },
            ];
          }
        });
  
        return updatedData;
      });
    }

    if (isMobile) {
      closeDrawerAI();
    }
  };  

  const handleDelete = (key, value) => {
    if (value && key === "roles") {
      setRolesToDelete(prev => [...prev, value]);

      setMissionStatementData((prevData) => ({
        ...prevData,
        roles: prevData.roles.filter((role) => role.value !== value),
      }));
    } else if (value && key === "values") {
      if (value && isUUID(value)) {
        setValuesToDelete(prev => [...prev, value]);
      }

      setMissionStatementData((prevData) => ({
        ...prevData,
        values: prevData.values.filter((val) => val.value !== value),
      }));
    }
  };

  const handleCreate = (key) => (value, callback) => {
    const type = key;

    const formData = new FormData();
    formData.append('name', value);

    if (type === 'roles') {
      // verify if the role already exists
      const existingRole = roleOption.find(i => i.label === value);

      if (existingRole) {
        callback(false);
        return;
      }

      callback(true);
      dispatch(CreateCustomRole(formData, handleCreated(type)));
    }
    else {
      // verify if the value already exists
      const existingValue = valueOption.find(i => i.label === value);

      if (existingValue) {
        callback(false);
        return;
      }

      callback(true);
      dispatch(CreateCustomValue(formData, handleCreated(type)));
    }
  }

  const handleCreated = type => data => {
    setTimeout(() => {
      setMissionStatementData((prevData) => ({
        ...prevData,
        [type]: [...prevData[type], { value: data.value, label: data.label, custom: true, isNew: true }],
      }));
    }, 100);
  }

  const deleteMissionstatement = () => {
    dispatch(
      DeleteMissionStatement(
        missionStatement.id,
        handleClose
      )
    );
  };

  const handleClose = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('mission-statement');
    navigate({ search: searchParams.toString() }, { replace: true });

    setMissionStatementData(null);
    setMissionStatementGoal(null);
    setIsEditing(false);
    setisLoading(false);
    setDeleteOpenModal(false);
  };

  const handleConfirmModal = () => {
    if (isEditing && currentStep < 7) {
      setIsConfirmExit(true);
    } else {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('mission-statement');
      navigate({ search: searchParams.toString() }, { replace: true });

      setisShow(false);
    }
  };

  const closeDrawerProgress = () => {
    setShowDrawerProgress(false);
  };

  const openDrawerProgress = () => {
    setShowDrawerProgress(true);
  };

  const closeDrawerAI = () => {
    setShowDrawerAI(false);
  };

  const openDrawerAI = () => {
    setShowDrawerAI(true);
  };

  const isDisabled = (key) => {
    switch(key) {
      case 'principle':
        return !missionStatementData?.principle?.name;
      case 'code_of_conduct':
        return !missionStatementData?.code_of_conduct?.name;
      case 'purpose':
        return !missionStatementData?.purpose?.name;
      case 'vision':
        return !missionStatementData?.vision?.name;
      default:
        return false;
    }
  }

  return (
    <Container>
      <Modal
        style={modalStyles}
        bodyStyle={modalBodyStyles}
        contentStyle={modalContentStyles}
        className={styles.modal}
        width={isMobile ? "100vw" : "1220px"}
        height={isMobile ? "100vh" : "calc(100vh - 90px)"}
        centered
        footer={null}
        open={true}
        maskClosable={false}
        onCancel={handleConfirmModal}
      >
        <Content>
          <ModalHeader>
            <Image src={StatmentIcon} />
            <Heading>MISSION STATEMENT</Heading>
          </ModalHeader>

          {isLoading && (
            <>
              <Header isBetween isheader>
                <PlaceholderColumn />

                <RolesHeading>
                  <Circle>{missionStatmentGoal?.priority}</Circle>{" "}
                  <MainRoleWrapper>
                    <MissionArea>{missionStatmentGoal?.area} </MissionArea>
                    <RolesSpan>{missionStatmentGoal?.category}</RolesSpan>
                  </MainRoleWrapper>
                </RolesHeading>

                <PlaceholderColumn />
              </Header>
              <StatmentContent isHeight>
                <Image src={StatmentGrayIcon} />

                <LargeHeading $loader className="mb-2">
                  Generating your mission statement
                </LargeHeading>
                
                <Spinner variant="secondary" style={{ width: 60 }} />
              </StatmentContent>
            </>
          )}

          {missionStatmentGoal && !isLoading && (currentStep === 7 || currentStep === 1 && !isEditing) && !isMobile && (
            <Header isBetween isheader>
              <PlaceholderColumn />

              <RolesHeading $end>
                <Circle>{missionStatmentGoal?.priority}</Circle>{" "}
                <MainRoleWrapper>
                  <MissionArea>{missionStatmentGoal?.area} </MissionArea>
                  <RolesSpan>{missionStatmentGoal?.category}</RolesSpan>
                </MainRoleWrapper>
              </RolesHeading>

              <DropdownWrapper>
                {missionStatmentGoal?.hasMissionStatement ? <MissionDropDown
                  setDeleteOpenModal={setDeleteOpenModal}
                  onExport={handlePrint}
                /> : null}
              </DropdownWrapper>
            </Header>
          )}

          {missionStatmentGoal?.hasMissionStatement &&
            !isLoading && (currentStep === 7 || currentStep === 1 && !isEditing) && 
            /* !isEditing && */ (
              <>
                <MissionComplete ref={$componentRef}>
                  <PrintHead />

                  <RolesHeading $printable>
                    <Circle>{missionStatmentGoal?.priority}</Circle>{" "}
                    <RoleHeading>{missionStatmentGoal?.area} </RoleHeading>
                    <RolesSpan isEllipse>{missionStatmentGoal?.category}</RolesSpan>
                  </RolesHeading>

                  <MissionHeading>Mission Statement</MissionHeading>
                  <AiSugesstionText $isLight>
                    {missionStatement?.conclusion}
                  </AiSugesstionText>
                  <CustomTable>
                    <CustomRow $isRow>
                      <Col span={11}>
                        <RowHeading>Roles</RowHeading>

                        <RowText>
                        {missionStatementData?.roles
                          ?.map((value) => value?.label)
                          .join(", ")}
                        </RowText>
                      </Col>
                      <Col span={11}>
                        <RowHeading>Values</RowHeading>
                        <RowText>
                          {/* {missionStatementData?.values.toString()} */}
                          {missionStatementData?.values
                            ?.map((value) => value?.label)
                            .join(", ")}
                        </RowText>
                      </Col>
                    </CustomRow>
                    <CustomRow>
                      <RowHeading>Principles</RowHeading>
                      <RowText>{missionStatementData?.principle?.name}</RowText>
                    </CustomRow>
                    <CustomRow>
                      <RowHeading>Code of Conduct</RowHeading>
                      <RowText>
                        {missionStatementData?.code_of_conduct?.name}
                      </RowText>
                    </CustomRow>
                    <CustomRow>
                      <RowHeading>Purpose (Statement)</RowHeading>
                      <RowText>{missionStatementData?.purpose?.name}</RowText>
                    </CustomRow>
                    <CustomRow $isBorderNone>
                      <RowHeading>Vision (Phrase)</RowHeading>
                      <RowText>{missionStatementData?.vision?.name}</RowText>
                    </CustomRow>
                  </CustomTable>
                </MissionComplete>

                <Wrapper /* $isBorder */>
                  {!isEditing ? <MissionButton
                    onClick={() => setIsEditing(true)}
                    text={"Edit"}
                  /> : 
                  <MissionButton text={"Close"} onClick={handleClose} />}
                </Wrapper>
              </>
            )}

          {!missionStatmentGoal?.hasMissionStatement &&
            !isEditing &&
            !isLoading && (
              <>
                {isMobile && (
                  <MainRoleDiv style={{ marginTop: '1rem' }}>
                    <Circle>{missionStatmentGoal?.priority}</Circle>{" "}
                    <MainRoleWrapper>
                      <MissionArea>{missionStatmentGoal?.area} </MissionArea>
                      <RolesSpan>{missionStatmentGoal?.category}</RolesSpan>
                    </MainRoleWrapper>
                  </MainRoleDiv>
                )}

                <StatmentContent>
                  <Image src={StatmentGrayIcon} />
                  <LargeHeading>Mission Statement Generator</LargeHeading>
                  <Text $main>
                    Your mission statement is the first step in designing your ideal life, career or business. It acts as a compass guiding you on your path to achievement.<br /><br />
                    This tool will help you create a mission statement that transforms your dreams into plans and passions into progress.
                  </Text>
                  <Button style={{ width: 238, marginTop: 28 }} onClick={() => setIsEditing(true)}>
                    Begin
                  </Button>
                </StatmentContent>
                {/* <Footer>
                <FooterHeading>
                  <Image src={Stars} />
                  AI Assistant <Span>Beta</Span>
                </FooterHeading>
              </Footer> */}
              </>
            )}

          {isEditing && !isLoading && currentStep < 7 && (
            <RolesContainer>
              {isEditing && !isMobile && !isTablet && (
                <StatementsProcess>
                  <StatementsProgress>
                    <Image src={ProgressIcon} />
                    <Text>Progress</Text>
                  </StatementsProgress>

                  {currentStep < 7 && (
                    <StepsWrapper>
                      <Steps
                        data={missionStatementData}
                        items={items}
                        step={currentStep}
                        // stepsValidation={dropdownSteps}
                        onChange={handleStepChange}
                      />
                    </StepsWrapper>
                  )}
                </StatementsProcess>
              )}

              <RolesContent>
                {currentStep < 7 && (
                  <MainRoleDiv $full={isMobile || isTablet}>
                    {(isMobile || isTablet) && (
                      <MainRoleDiv onClick={openDrawerProgress}>
                        <Image src={MenuExpandLeft} $mr /> Progress{" "}
                      </MainRoleDiv>
                    )}
                    {!isMobile && (
                      <MainRoleDiv>
                        <Circle>{missionStatmentGoal?.priority}</Circle>{" "}
                        <MainRoleWrapper>
                          <MissionArea>{missionStatmentGoal?.area}{" "}</MissionArea>
                          <RolesSpan>{missionStatmentGoal?.category}</RolesSpan>
                        </MainRoleWrapper>
                      </MainRoleDiv>
                    )}
                    {(isMobile || isTablet) && (
                      <MainRoleDiv onClick={openDrawerAI}>
                        AI Assistant <Image $mr $ml src={MenuExpandRight} />
                      </MainRoleDiv>
                    )}
                  </MainRoleDiv>
                )}

                {isMobile && (
                  <MainRoleDiv>
                    <Circle>{missionStatmentGoal?.priority}</Circle>{" "}
                    <MainRoleWrapper>
                      <MissionArea>{missionStatmentGoal?.area} </MissionArea>
                      <RolesSpan>{missionStatmentGoal?.category}</RolesSpan>
                    </MainRoleWrapper>
                  </MainRoleDiv>
                )}

                <BodyWrapper>
                  {items?.map(
                    (item, i) =>
                      currentStep === item?.step && (
                        <>
                          <TextsWrapper key={i}>
                            <XlargeHeading>
                              {item?.title}{" "}
                              {item?.step !== 7 && (
                                <Tooltip
                                  placement="rightTop"
                                  type="secondary"
                                  title={item?.tooltipDesc}
                                  customOverlayClassName={styles.tooltip}
                                  align={{
                                    offset: [-2, 6],
                                  }}
                                >
                                  <Tooltipicon style={{ marginTop: "4px" }} />
                                </Tooltip>
                              )}{" "}
                            </XlargeHeading>

                            {item?.step !== 7 && (
                              <>
                                <RolesText>{item?.disc}</RolesText>
                                <SmallText>{item?.suggestion}</SmallText>{" "}
                              </>
                            )}
                          </TextsWrapper>

                          {item?.step !== 7 && (
                            <SelectWrapper>
                              {item?.step < 3 ? (
                                <MissionSelect
                                  onSelect={(rest) =>
                                    handleSelect(item?.key, rest)
                                  }
                                  onDeselect={(rest) => {
                                    handleDelete(item?.key, rest)
                                  }}
                                  onCreate={handleCreate(item?.key)}
                                  text={item?.title}
                                  placeholder={item?.placeholder}
                                  value={
                                    missionStatementData &&
                                    missionStatementData[item?.key]
                                  }
                                  type={item?.key}
                                  options={getOptions(item?.step, roles, values, rest => handleDelete(item?.key, rest))}
                                  error={missionStatementData && missionStatementData[item?.key].length > 3 ? item.error : null}
                                />
                              ) : (
                                <InputWrapper>
                                  <EditableInput
                                    text={item?.label}
                                    value={
                                      missionStatementData && missionStatementData[item?.key]?.name && missionStatementData['roles'][0].label
                                        ? missionStatementData[item?.key]?.name.replace('{role}', `${getArticle(missionStatementData['roles'][0].label)} ${missionStatementData['roles'][0].label.toLowerCase()}`)
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleChange(e.target.value, item?.step, true);
                                    }}
                                    isEditing={true}
                                    placeholder={`${item?.step == 3 && missionStatementData['roles'][0].label ? item?.placeholder.replace('{role}', `${getArticle(missionStatementData['roles'][0].label)} ${missionStatementData['roles'][0].label.toLowerCase()}`) : item?.placeholder}...`}
                                    errorMessage={errorMessage}
                                  />
                                </InputWrapper>
                              )}
                            </SelectWrapper>
                          )}

                        {item?.step === 7 && (
                          <>
                            {/* <AiSugesstionText>
                              {missionStatementData?.conclusion}
                            </AiSugesstionText> */}
                            {/* <Wrapper>
                              <RegGernerateButton>
                                📝 Enhance
                              </RegGernerateButton>
                              <RegGernerateButton>
                                🎵 Change Tone <Image src={DowIcon} />
                              </RegGernerateButton>
                            </Wrapper> */}
                          </>
                        )}

                        {item?.step === 7 ? (
                          <MissionButton text={"Close"} onClick={handleClose} />
                        ) : (
                          <MissionButton
                            text={isLoading ? "Loading" : "Continue"}
                            onClick={() => handleSave(item)}
                            disabled={
                              currentStep > 2
                                ? !missionStatementData[item?.key]?.name || isDisabled(item?.key)
                                : !missionStatementData[item?.key]?.length || missionStatementData[item?.key]?.length > 3 || isLoading
                            }
                          />
                        )}
                      </>
                    )
                )}
                </BodyWrapper>
              </RolesContent>

              {!isMobile && !isTablet && (
                <RoleAI>
                  {currentStep < 7 ? (
                    <AIHelper
                      step={currentStep}
                      missionStatement={missionStatementData}
                      goal={{
                        area: missionStatmentGoal?.area,
                        category: missionStatmentGoal?.category,
                        domain: domainName,
                      }}
                      prompts={{
                        principle: items[2].placeholder,
                        code_of_conduct: items[3].placeholder,
                        purpose: items[4].placeholder,
                        vision: items[5].placeholder
                      }}
                      onSelect={handleAISelect}
                    />
                  ) : null}
                </RoleAI>
              )}
            </RolesContainer>
          )}
        </Content>
      </Modal>

      {deleteOpenModal && (
        <DeleteModal
          missionStatmentGoal={missionStatmentGoal}
          deleteOpenModal={deleteOpenModal}
          setDeleteOpenModal={setDeleteOpenModal}
          onClick={deleteMissionstatement}
          deleteMissionloading={deleteMissionloading}
          title={"Delete Mission Statement"}
          description={"Are you sure you want to delete Mission statement for"}
        />
      )}

      <ExitConfirmModal
        isOpen={isConfirmExit}
        onOK={handleClose}
        onCancel={() => setIsConfirmExit(false)}
      />

      <Drawer
        width={isMobile ? "90%" : "40%"}
        placement="left"
        onClose={closeDrawerProgress}
        open={showDrawerProgress}
        headerStyle={{ padding: 0, marginBottom: 0 }}
        bodyStyle={{ padding: 0 }}
        closable={false}
      >
        <StatementsProcess $full>
          <DrawerHeader>
            <StatementsProgress>
              <Image src={ProgressIcon} />
              <Text>Progress</Text>
            </StatementsProgress>
            <MainRoleDiv onClick={closeDrawerProgress}>
              <Image $mr $ml src={MenuExpandLeft} />
            </MainRoleDiv>
          </DrawerHeader>

          {currentStep < 7 && (
            <StepsWrapper>
              <Steps
                data={missionStatementData}
                items={items}
                step={currentStep}
                onChange={handleStepChange}
              />
            </StepsWrapper>
          )}
        </StatementsProcess>
      </Drawer>

      <Drawer
        width={isMobile ? "90%" : "40%"}
        placement="right"
        onClose={closeDrawerAI}
        open={showDrawerAI}
        headerStyle={{ padding: 0, marginBottom: 0 }}
        bodyStyle={{ padding: 0 }}
        closable={false}
      >
        <RoleAI $full>
          {currentStep < 7 ? (
            <AIHelper
              step={currentStep}
              missionStatement={missionStatementData}
              goal={{
                area: missionStatmentGoal?.area,
                category: missionStatmentGoal?.category,
                domain: domainName,
              }}
              prompts={{
                principle: items[2].placeholder,
                code_of_conduct: items[3].placeholder,
                purpose: items[4].placeholder,
                vision: items[5].placeholder
              }}
              onSelect={handleAISelect}
              onClose={closeDrawerAI}
            />
          ) : null}
        </RoleAI>
      </Drawer>
    </Container>
  );
};

export default MissionStatment;
