import styled from "styled-components";
import React, { useEffect, useState, useMemo } from "react";
import { Checkbox } from "elements";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EmptyListDomain, ListDomain } from "Redux/ListDomains/action";
import {
  CreateMasterplanSelectJourney,
  CreateMasterplanSetError,
} from "Redux/CreateMasterPlanSelectJourney/action";
import Loading from "components/Loader";
import { getCookie, setCookie } from "utils/StorageVariables";

import CreditModal from "components/CreditModal";

import { UpdateLastStep } from "Redux/UpdateLastStep/action";
import ConfirmationModal from "components/ConfirmationModal";

import PvtDomainModal from "components/PvtDomainModal/PvtDomainModal";

import { userTypes } from "utils/utility";
import { PREMISE_PATH, MISSION_PATH } from "utils/routes";

import JourneyItem from "./Item";
import AreasList from "./AreasList";
import { SetSelectedGoalAction } from "Redux/Global/action";

const ChooseWorkshop = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [listDomain, setListDomain] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [creditModal, setCreditModal] = useState(false);
  const [selectedName, setSelectedName] = useState("");

  const [confirmModal, setConfirmModal] = useState(false);
  const [pvtDomainModal, setPvtDomainModal] = useState(false);
  const [selectedJourney, setSelectedJourney] = useState(null);

  const { domainList } = useSelector((state) => state.domainListReducer);
  const { SelectedClient } = useSelector((state) => state.globalReducer);

  const [filterDomain, setFilterDomain] = useState([
    {
      status: true,
      title: "Personal",
      label: "Personal",
      color: "#B15DF3",
    },
    {
      status: true,
      title: "Professional",
      label: "Career",
      color: "#59A310",
    },
    {
      status: true,
      title: "Business",
      label: "Business",
      color: "#1689CA",
    },
  ]);

  const { userDetail } = useSelector(
    (state) => state.userDetailReducer
  );

  useEffect(() => {
    let selectedClient = getCookie("selectedClient");

    if (!selectedClient)
      dispatch(ListDomain());

    if (SelectedClient) {
      dispatch(ListDomain(SelectedClient?.id));
    }

    return () => {
      dispatch(EmptyListDomain(null));
    };
  }, [SelectedClient]);

  useEffect(() => {
    let filters = filterDomain
      ?.filter((item) => item?.status)
      ?.map((item) => item?.title);

    setListDomain(
      domainList?.filter(
        (item) => filters?.includes(item?.type) && item?.visible
      )
    );
  }, [filterDomain, domainList]);

  const onSuccess = (domainId, domainName) => {
    setCookie("domainId", domainId);
    setCookie("domainName", domainName);

    // navigate(PREMISE_PATH);
    navigate(MISSION_PATH);
  };

  const onContinueHandle = (item) => {
    const id = item?.id,
      masterId = item?.current_state?.id,
      domainName = item?.name,
      pvtStatus = item?.current_state?.is_private;

    if (userDetail?.type === userTypes?.coach && pvtStatus)
      return setPvtDomainModal(true);

    setCookie("MasterPlanId", masterId);
    setCookie("domainName", domainName);
    setCookie("JourneyId", id);
    setCookie("domainId", id);

    const stepFormData = new FormData();
    stepFormData.append("step", 0);

    dispatch(SetSelectedGoalAction(false));
    dispatch(UpdateLastStep(stepFormData));

    navigate(MISSION_PATH);
  };

  const handleCreateMasterplan = (domainId, domainName) => {
    const formData = new FormData();
    formData.append("step", 0);
    formData.append("journey", domainId);
    dispatch(
      CreateMasterplanSelectJourney(formData, domainName, domainId, onSuccess)
    );
  };

  const hanldeStart = (id) => {
    const domain = listDomain.find((item) => item.id === id);

    if (!domain) return;

    if (userDetail.type === userTypes.coach && domain.current_state?.is_private)
      return setPvtDomainModal(true);

    setSelectedDomain({ name: domain.name, id });

    const creditsStr = getCookie("credits");
    const credits = parseFloat(creditsStr);

    if (!creditsStr || isNaN(credits) || credits < 1) {
      return setCreditModal(true);
    }

    return setConfirmModal(true);
  };

  const handlePrivateToggle = (id) => {
    const domain = listDomain.find((item) => item.id === id);

    if (!domain) return;

    const pvtFormData = new FormData();
    pvtFormData.append("is_private", !domain.current_state?.is_private);

    dispatch(UpdateLastStep(pvtFormData, true, domain.current_state?.id));
  };

  const handleViewAreas = (id) => {
    setSelectedJourney(id);
  };

  const handleFilter = (option) => () => {
    setFilterDomain((prev) =>
      prev.map((item) =>
        item.title === option.title ? { ...item, status: !item.status } : item
      )
    );
  };

  const areas = useMemo(() => {
    if (!listDomain) return [];

    const activeJourney = listDomain.filter(
      (domain) => domain.id === selectedJourney
    );

    if (!activeJourney.length) return [];

    return activeJourney[0]?.subdomains?.[0]?.areas || [];
  }, [listDomain, selectedJourney]);

  return (
    <Wrapper>
      <CreditModal
        open={creditModal}
        setOpen={() => {
          dispatch(CreateMasterplanSetError(null));
          setSelectedDomain(null);
          setCreditModal(false);
        }}
        availableCredit={getCookie("credits")}
        domain={selectedDomain?.name}
      />

      <PvtDomainModal
        open={pvtDomainModal}
        setPvtDomainModal={setPvtDomainModal}
      />

      <ConfirmationModal
        open={confirmModal}
        setOpen={() => {
          dispatch(CreateMasterplanSetError(null));
          setSelectedDomain(null);
          setConfirmModal(false);
        }}
        handleStart={() =>
          handleCreateMasterplan(selectedDomain?.id, selectedDomain?.name)
        }
        availableCredit={getCookie("credits")}
        domain={selectedDomain?.name}
      />

      <Loading />

      <FilterContainer>
        <FilterTitle>Filter by Domains</FilterTitle>
        <FilterItems>
          {filterDomain?.map((item, i) => (
            <Checkbox
              label={item.label}
              color={item.color}
              id={item.title.toLowerCase()}
              key={item.title}
              checked={item.status}
              onChange={handleFilter(item)}
            />
          ))}
        </FilterItems>
      </FilterContainer>

      <ContentList>
        <ContentListBody>
          {listDomain?.map((item) => (
            <JourneyItem
              data={item}
              coachView={!!SelectedClient}
              onStart={hanldeStart}
              onContinue={onContinueHandle}
              onPrivateToggle={handlePrivateToggle}
              onViewAreas={handleViewAreas}
              setSelectedName={setSelectedName}
              key={item.id}
              userDetail={userDetail}
            />
          ))}
        </ContentListBody>
      </ContentList>

      {
        <AreasList
          isActiveState={!!selectedJourney}
          data={areas}
          selectedName={selectedName}
          onClose={() => setSelectedJourney(null)}
        />
      }
    </Wrapper>
  );
};

export default ChooseWorkshop;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 38px);
`;

const ContentList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 12px 20px;

  ${({ theme }) => theme.min("sm")`
    ::-webkit-scrollbar {
      width: 26px;
    }

    ::-webkit-scrollbar-track {
      background: transparent !important;
    }
    
    ::-webkit-scrollbar-thumb {
      border: 10px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #acb4bc;
    }
  `}
`;

const ContentListBody = styled.div`
  /* display: flex;
  flex-wrap: wrap;
  gap: 20px; */
  /* border: 1px solid green */
  gap: 20px;
  display: grid;
  justify-content: flex-start;
  grid-template-columns: repeat(auto-fill, minmax(min(320px, 440px), auto));

  @media (max-width: 743px) {
    grid-template-columns: 1fr; /* 1 item per row */
  }
`;

const FilterContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #c1cad1;
  background-color: #fff;
  padding: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: row;

  ${({ theme }) => theme.max("sm")`
      flex-direction: column;
    `}
`;

const FilterItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: row;

  ${({ theme }) => theme.max("sm")`
    gap: 30px;
  `}
`;

const FilterTitle = styled.span`
  color: var(--fill-frame-shadow-blue, #818ea3);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;
