import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 426px;
  border-radius: 8px;
  border: 1px solid #E3E5E9;
  background: #FFF;
  box-shadow: 6px 1px 13.9px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
`;

export const Title = styled.span`
  color: #6B7280;
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

export const Text = styled.span`
  color: #767A85;
  font-family: Inter;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
`;

export const Close = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  background: transparent;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

export const Item = styled.div`
  width: calc(50% - 15px);

  label > span {
    color: #6B7280;
    font-weight: 500;
    line-height: 14px;
  }
`;
