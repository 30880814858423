import { updateMissionStatement } from "api";
import { Error } from "Responses/Response";
import { ActionTypes } from "./types";
import { SetMissionStatement } from "Redux/GetMissionStatement/action";

export const UpdateMissionStatement = (Data, id, callback) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { data, status } = await updateMissionStatement(Data, id);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });

    if (status === 200) {
      dispatch(SetMissionStatement(data));
      
      if (callback) {
        callback();
      }
    }
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};

export const SetUpdateMissionStatement = (payload) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SUCCESS,
    payload,
  });
};
