import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Content,
  Row,
  Col,
  ValueRow,

  Label,
  Value,

  Title,
  Icon
} from './styled';

// @ts-ignore
import primaryIcon from "assets/images/achievements/crisis_alert.svg";

// @ts-ignore
import secondaryIcon from "assets/images/achievements/checklist.svg";

import type { IState } from '@types';

import { useQueryParams } from 'utils/useQueryParams';
import useWindowSize from 'utils/useWindowSize';

// @ts-ignore
import { BREAKPOINTS } from "theme/mixins";

import { GetAreasProgress } from 'Redux/Achievements/ListAreasProgress/action';

interface IData {
  id: string;
  label: string;
  goal: number;
  plan: number;
}

interface IProps {
  data: IData[];
}

const JourneyDetails: FC<IProps> = ({ data }) => {
  const dispatch = useDispatch();

  const { data: areasProgress } = useSelector((state: IState) => state.listAreasProgress);

  const [journey, setJourney] = useState<IData | null>(null);

  const { trend: trendIndex } = useQueryParams();
  const { width } = useWindowSize();

  useEffect(() => {
    const masterPlan = data[parseInt(trendIndex || '1') - 1] as IData || null;

    if (masterPlan) {
      setJourney(masterPlan);
    }
  }, [trendIndex, data]);

  useEffect(() => {
    if (journey?.id) {
      dispatch(GetAreasProgress({
        domain: journey?.id
      }));
    }
  }, [journey?.id]);

  if (!journey)
    return null;

  const isMobileView = !!(width && width < BREAKPOINTS.sm);

  return (
    <Content>
      <Row $primary>
        <Col $primary>
          <Title>
            {isMobileView ? `Areas` : `${journey.label} Journey`}
          </Title>
        </Col>
        <ValueRow $small>
          <Col $secondary>
            <Title $bold>
              <Icon src={primaryIcon} alt="" />
              {isMobileView ? null : "Goal"}
            </Title>
          </Col>
          <Col $secondary>
            <Title $bold>
              <Icon src={secondaryIcon} alt="" />
              {isMobileView ? null : "Plan"}
            </Title>
          </Col>
        </ValueRow>
      </Row>

      {areasProgress.map(item => <Row key={item.user_area_name}>
        <Col $primary>
          <Label>{item.user_area_name}</Label>
        </Col>
        <ValueRow $small={isMobileView}>
          <Col $secondary>
            <Value>{parseFloat(item.goal_progress.toFixed(1))}%</Value>
          </Col>
          <Col $secondary>
            <Value>{parseFloat(item.plan_progress.toFixed(1))}%</Value>
          </Col>
        </ValueRow>
      </Row>)}
    </Content>
  );
}

export default JourneyDetails;
