// @ts-ignore
import { getHistoricalData } from "api";
// import Notify from "utils/notify";

import { ActionTypes } from "./types";
import { DateType } from "components/Achievements/LineChart";

interface IPayload {
  domain?: string;
  area?: string;
  start_date?: string;
  end_date?: string;
  type?: DateType;
}

interface DataItem {
  completed_count: number;
  uncompleted_count: number;
  date: string;
}

type CombinedDataMap = Map<string, { date: string; goal_value?: number; action_value?: number }>;

export const GetHistoricalData = (payload = {} as IPayload) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const filtersParams: { [key: string]: any } = {};

    if (payload?.domain) {
      filtersParams['masterplan_id'] = payload?.domain;
    }

    if (payload?.area) {
      filtersParams['user_area_name'] = payload?.area;
    }

    if (payload?.start_date) {
      filtersParams['start_date'] = payload?.start_date;
    }

    if (payload?.end_date) {
      filtersParams['end_date'] = payload?.end_date;
    }

    if (payload?.type) {
      filtersParams['date_type'] = payload?.type;
    }

    const { data: goalData } = await getHistoricalData({ ...filtersParams, model_name: 'Goal' });
    const { data: actionData } = await getHistoricalData({ ...filtersParams, model_name: 'Action' });

    // Create a map to store results by date
    const combinedDataMap: CombinedDataMap = new Map();

    // Helper function to process data
    const processData = (data: DataItem[], key: 'goal_value' | 'action_value'): void => {
      data.forEach(({ completed_count, uncompleted_count, date }) => {
        const value = completed_count - uncompleted_count;
    
        if (!combinedDataMap.has(date)) {
          combinedDataMap.set(date, { date });
        }
    
        combinedDataMap.get(date)![key] = value;
      });
    };

    // Process goalData
    processData(goalData, 'goal_value');

    // Process actionData
    processData(actionData, 'action_value');

    // Convert map values into an array
    const finalData = Array.from(combinedDataMap.values());

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: finalData,
    });
  } catch (error) {
    console.error(error);
    // Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
