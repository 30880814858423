import { FC } from 'react';
import { Accordion } from "react-bootstrap";
import { Button } from 'elements';

// @ts-ignore
import collapseIcon from "assets/images/priorities/collapse-arrow.svg";

import {
  Wrapper,
  Head,
  HeadGroup,
  Icon,
  IconPlaceholder,
  Title,
  Toggle,
  Body,
  BodyInner,
  Content
} from './styled';

import { IMissionStatement } from 'Redux/ListMissionStatements/types';

interface IItemProps {
  data: IMissionStatement;
  onToggle: (id: string) => void;
  onEdit: (id: string) => void;
}

const Item:FC<IItemProps> = ({ data, onToggle, onEdit }) => {
  const { id, color, area, conclusion, goal, icon } = data;

  const onClickHandle = () => {
    onToggle(id);
  };

  const handleEdit = () => {
    onEdit(goal);
  };

  return (
    <Wrapper $color={color}>
      <Head onClick={onClickHandle}>
        <HeadGroup>
          {icon
            ? <Icon src={icon} alt="" />
            : <IconPlaceholder />
          }
          <Title>{area}</Title>
        </HeadGroup>

        <Toggle type="button">
          <img src={collapseIcon} alt="" />
        </Toggle>
      </Head>

      <Accordion.Collapse as={Body} eventKey={id}>
        <BodyInner>
          <Content>{conclusion}</Content>
          <Button styleType='link-primary' onClick={handleEdit}>Edit</Button>
        </BodyInner>
      </Accordion.Collapse>
    </Wrapper>
  );
};

export default Item;
