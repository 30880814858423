import styled, { css } from 'styled-components';

const colors = {
  primary: {
    goal: '#17C1D9',
    plan: '#FF5733',
  },
  secondary: {
    goal: '#17C1D9',
    plan: '#7400CF',
  }
};

export const Container = styled.div`
  ${({ theme }) => theme.max("sm")`
    display: flex;
    flex-direction: column;
    padding-top: 14px;
    border-top: 1px solid rgba(229, 231, 235, 0.5)
  `}
`;

export const Wrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.max("sm")`
    display: flex;
    align-items: stretch;
    margin-top: 0;
    padding: 10px;
    border-radius: 11px;
    border: 1px solid rgba(243, 244, 246, 0.50);
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  ${({ theme }) => theme.max("sm")`
    flex: 1;
  `}
`;

export const Header = styled.span`
  color: #232323;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 12px;
`;

export const Hr = styled.div`
  margin: 0 16px;
  min-width: 1px;
  background: #F3F4F6;
`;

interface ITitle {
  $bold?: boolean;
}

export const Title = styled.span<ITitle>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #6B7280;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.14px;
  text-align: center;

  ${({ $bold }) => $bold && css`
    justify-content: center;
    color: #000;
    font-family: Inter;
    font-weight: 600;
    letter-spacing: 0;

    ${({ theme }) => theme.max("sm")`
      width: 32px;
    `}
  `}

  ${({ theme }) => theme.max("sm")`
    font-size: 10px;
    line-height: 14px;
  `}
`;

interface IIcon {
  $rotate?: number;
}

export const Icon = styled.img<IIcon>`
  width: 18px;
  height: 18px;
  
  ${({ $rotate }) => $rotate && css`
    transform: rotate(${$rotate}deg);
  `}
`;

export const Chart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 25%;
`;

export const ChartHead = styled.div`
  height: 20px;
`;

export const ChartBody = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  min-height: 87px;
`;

export const BarWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 28px;
  align-items: center;
  justify-content: flex-end;
`;

interface IBar {
  $type: 'goal' | 'plan';
  $width: number;
}

export const Bar = styled.div<IBar>`
  width: 3px;
  border-radius: 1.3px;
  background: #E88614;

  ${({ $type }) => $type && css`
    background: ${colors.primary[$type]};
  `}

  ${({ $width }) => $width && css`
    height: ${$width}%
  `}
`;

interface IBarValue {
  $type: 'goal' | 'plan';
}

export const BarValue = styled.span<IBarValue>`
  text-align: center;
  font-family: Inter;
  font-size: 8px;

  ${({ $type }) => $type && css`
    color: ${colors.primary[$type]};
  `}
`;

export const ChartFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 9px;
`;

export const Dots = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

interface IDot {
  $active?: boolean;
}

export const Dot = styled.div<IDot>`
  width: 4px;
  height: 4px;
  background: #D9D9D9;
  border-radius: 50%;

  ${({ $active }) => $active && css`
    background: #4F90A6;
  `}
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const PagiButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  border-radius: 8px;
  border: 1px solid #D1D5DB;
  background: transparent;
  padding: 0;

  :disabled {
    opacity: .25;
  }
`;

export const CurrentPage = styled.span`
  display: flex;
  align-items: center;
  height: 27px;
  border-radius: 8px;
  border: 1px solid #D1D5DB;
  padding: 0;
  color: #6B7280;
  padding: 0 16px;
  font-family: Inter;
  font-size: 12px;
  line-height: 12px;
`;
