import moment from 'moment';

import CalendarWidget from './CalendarWidget';
import FilterWidget from './FilterWidget';

import {
  Wrapper,
  Top,
  Content
} from './styled';

import { useQueryParams } from 'utils/useQueryParams';
import { useEffect } from 'react';

const Header = () => {
  const { start, end, setQueryParams } = useQueryParams();

  useEffect(() => {
    if (!start || !end) {
      const filters = {
        start: moment().startOf('week').format('YYYY-MM-DD'),
        end: moment().endOf('week').format('YYYY-MM-DD'),
      };

      setQueryParams(filters);
    }
  }, [start, end]);

  const handleDateRangeChange = (values: any) => {
    const filters = {
      start: values[0].format('YYYY-MM-DD'),
      end: values[1].format('YYYY-MM-DD'),
    };

    setQueryParams(filters);
  }

  const dateRange = [
    start ? moment(start) : moment().startOf('week'),
    end ? moment(end) : moment().endOf('week')
  ] as any;

  return (
    <Wrapper>
      <Top>Achievements</Top>

      <Content>
        <FilterWidget />

        <CalendarWidget
          value={dateRange}
          onChange={handleDateRangeChange}
        />
      </Content>
    </Wrapper>
  );
}

export default Header;
