import { FC, InputHTMLAttributes, ReactNode } from 'react';
//@ts-ignore
import { ReactComponent as UncheckedIcon } from "assets/images/journeys/checkbox-unchecked.svg";

import styled, { css } from 'styled-components';

interface CheckedIconProps {
  color?: string;
}

const CheckedIcon: FC<CheckedIconProps> = ({ color = '#1271A6' }) => {
  return (
    <svg data-testid="checked-icon" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <g clipPath="url(#clip0_8487_218536)">
        <rect x="0.76001" y="0.519043" width="18" height="18" rx="4" fill={color} />
        <path d="M6.01001 9.51904L8.68858 11.769L13.51 7.26904" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_8487_218536">
          <rect x="0.76001" y="0.519043" width="18" height="18" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: ReactNode;
  color?: string;
  rtl?: boolean;
  width?: string;
}

const Checkbox: FC<CheckboxProps> = ({ label, id, color, rtl, width, ...props }) => {
  const renderLabel = () => {
    if (typeof label === 'string')
      return <Content>{label}</Content>;
    return label;
  };

  return (
    <Wrapper $width={width}>
      <Input data-testid="test-checkbox" id={id} type="checkbox" {...props} />
      <Label data-testid="test-label" htmlFor={id} $gap={!!label} $rtl={rtl} $align={typeof label === 'string'}>
        {props?.checked ? <CheckedIcon color={color} /> : <UncheckedIcon data-testid="unchecked-icon" />}
        {renderLabel()}
      </Label>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $width?: string; }>`
  display: flex;

  ${({ $width }) => $width && css`
    width: ${$width};
  `}
`;

const Input = styled.input`
  position: fixed;
  width: 0;
  height: 0;
  z-index: -1;
  font-size: 0;
  opacity: 0;
  visibility: hidden;

  :disabled {
    + label {
      opacity: 0.6;
      cursor: default;
    }
  }
`;

const Label = styled.label<{ $gap?: boolean; $rtl?: boolean; $align?: boolean; }>`
  flex: 1;
  display: flex;
  cursor: pointer;

  ${({ $gap }) => $gap && css`
    gap: 13px;
  `}

  ${({ $align }) => $align && css`
    align-items: center;
  `}

  ${({ $rtl }) => $rtl && css`
    flex-direction: row-reverse;
  `}
`;

const Content = styled.span`
  color: var(--fill-icon-oxford-blue, #06203A);
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  user-select: none;
`;

export default Checkbox;
